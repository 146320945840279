import AndroidIcon from '../../assets/technologies/android.svg';
import CSharpIcon from '../../assets/technologies/c-sharp.svg';
import FirebaseIcon from '../../assets/technologies/firebase.svg';
import HtmlCssIcon from '../../assets/technologies/html-css.svg';
import IosIcon from '../../assets/technologies/ios.svg';
import JavaIcon from '../../assets/technologies/java.svg';
import JavaScriptIcon from '../../assets/technologies/javascript.svg';
import KotlinIcon from '../../assets/technologies/kotlin.svg';
import MongoIcon from '../../assets/technologies/mongo.svg';
import NodeIcon from '../../assets/technologies/node.svg';
import ObjectiveIcon from '../../assets/technologies/objective.svg';
import PhpIcon from '../../assets/technologies/php.svg';
import RestIcon from '../../assets/technologies/rest.svg';
import SharepointIcon from '../../assets/technologies/sharepoint.svg';
import SwiftIcon from '../../assets/technologies/swift.svg';
import VueIcon from '../../assets/technologies/vue.svg';
import PMI from '../../assets/technologies/pmi.svg';
import Kanban from '../../assets/technologies/kanban.svg';
import Git from '../../assets/technologies/git.svg';
import Agile from '../../assets/technologies/agile.svg';
import Scrum from '../../assets/technologies/scrum.svg';
import Confluence from '../../assets/technologies/confluence.svg';
import Jira from '../../assets/technologies/jira.svg';
import MsProject from '../../assets/technologies/ms-project.svg';
import Redmine from '../../assets/technologies/redmine.svg';
import DbTesting from '../../assets/technologies/db-testing.svg';
import Postman from '../../assets/technologies/postman.svg';
import TestManagement from '../../assets/technologies/test-managment.svg';
import SpiraTeam from '../../assets/technologies/spira-team.svg';
import Selenium from '../../assets/technologies/selenium.svg';
import Soapui from '../../assets/technologies/soapui.svg';
import TestNg from '../../assets/technologies/test-ng.svg';
import Katalon from '../../assets/technologies/katalon.svg';
import JMeter from '../../assets/technologies/j-meter.svg';
import MaterialDesign from '../../assets/technologies/material-design.svg';
import Sketch from '../../assets/technologies/sketch.svg';
import Axure from '../../assets/technologies/axure.svg';
import Figma from '../../assets/technologies/figma.svg';
import FlatDesign from '../../assets/technologies/flat-design.svg';
import Carbon from '../../assets/technologies/carbon.svg';
import MetroDesign from '../../assets/technologies/metro-design.svg';
import Bootstrap from '../../assets/technologies/bootstrap.svg';
import GameDesign from '../../assets/technologies/game-design.svg';
import Adobe from '../../assets/technologies/adobe.svg';
import HealthCare from '../../assets/technologies/health-care.svg';
import Duration from '../../assets/technologies/duration.svg';
import Travel from '../../assets/technologies/travel.svg';
import Social from '../../assets/technologies/social.svg';

const techStackIcons = {
  ANDROID: AndroidIcon,
  IOS: IosIcon,
  KOTLIN: KotlinIcon,
  PHP: PhpIcon,
  NODE: NodeIcon,
  VUE: VueIcon,
  SHAREPOINT: SharepointIcon,
  JAVA_SCRIPT: JavaScriptIcon,
  C_SHARP: CSharpIcon,
  FIREBASE: FirebaseIcon,
  SWIFT: SwiftIcon,
  MONGO: MongoIcon,
  JAVA: JavaIcon,
  REST: RestIcon,
  OBJECTIVE: ObjectiveIcon,
  HTML_CSS: HtmlCssIcon,
  PMI: PMI,
  KANBAN: Kanban,
  GIT: Git,
  AGILE: Agile,
  SCRUM: Scrum,
  CONFLUENCE: Confluence,
  JIRA: Jira,
  MS_PROJECT: MsProject,
  REDMINE: Redmine,
  DB_TESTING: DbTesting,
  POSTMAN: Postman,
  TEST_MANAGEMENT: TestManagement,
  SPIRA_TEAM: SpiraTeam,
  SELENIUM: Selenium,
  SOAPUI: Soapui,
  TEST_NG: TestNg,
  KATALON: Katalon,
  J_METER: JMeter,
  MATERIAL_DESIGN: MaterialDesign,
  SKETCH: Sketch,
  AXURE: Axure,
  FIGMA: Figma,
  FLAT_DESIGN: FlatDesign,
  CARBON: Carbon,
  METRO_DESIGN: MetroDesign,
  BOOTSTRAP: Bootstrap,
  GAME_DESIGN: GameDesign,
  ADOBE: Adobe,
  'HEALTH CARE': HealthCare,
  'МЕДИЦИНА': HealthCare,
  TRAVEL: Travel,
  'ПОДОРОЖІ': Travel,
  'SOCIAL / ENTERTAINMENT': Social,
  'СОЦІАЛЬНІ МЕРЕЖІ / РОЗВАГИ': Social,
  DURATION: Duration,
};

export default techStackIcons;
