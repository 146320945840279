import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CaseItem from './CaseItem';
import { Constants } from '../constants';
import H2 from '../../../components/typography/H2';
import TextLarge from '../../../components/typography/TextLarge';
import Button from '../../../components/buttons/Button';
import { isEnglish } from '../../../utils/helpers';

export default function CaseStudies() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const caseStudies = Constants[`CASE_STUDIES_${language.toUpperCase()}`];

  return (
    <section className="case-studies-section" id="case-studies-section-view">
      <div className="container case-studies-container">
        {isEnglish(language) && (
          <Link to={'/case-studies'} className="link-to-cases">
            <Button
              $secondary
              $width="328px"
              $height="51px"
              className="case-button-mobile"
            >
              See more
            </Button>
          </Link>
        )}

        <div className="case-studies-items">
          {caseStudies.map((caseInfo) => {
            return <CaseItem key={caseInfo.id} caseInfo={caseInfo} />;
          })}
        </div>

        <div className="case-studies-header">
          <H2 $align="left" $margin="0 0 30px 0">
            {t('homePageCaseStudiesTitle')}
          </H2>
          <div className="case-studies-info">
            <TextLarge $align="left">
              {t('homePageCaseStudiesDescription')}
            </TextLarge>

            {isEnglish(language) && (
              <Link to={'/case-studies'} className="link-to-cases">
                <Button
                  $secondary
                  $width="312px"
                  $height="51px"
                  className="case-button"
                >
                  See more
                </Button>
              </Link>
            )}
            {!isEnglish(language) && (
              <Link to={'/case-studies'} className="link-to-cases">
                <Button
                  $secondary
                  $width="312px"
                  $height="51px"
                  className="case-button"
                >
                  Більше
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="case-studies-container-mobile">
        {!isEnglish(language) && (
          <Link to={'/case-studies'} className="link-to-cases">
            <Button
              $secondary
              $width="312px"
              $height="51px"
              className="case-button"
            >
              Більше
            </Button>
          </Link>
        )}
      </div>
    </section>
  );
}
