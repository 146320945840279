import React from 'react';
import { Link } from 'react-router-dom';
import Joi from 'joi';
import Form from '../../components/forms/Form';
import InputMask from 'react-input-mask';

class ContactForm extends Form {
  state = {
    data: {
      fullName: '',
      email: '',
      phone: '',
      message: '',
      isChecked: false,
    },
    errors: {},
    stage: {
      sending: false,
      success: false,
      error: false,
    },
  };

  // Validation requirements
  schemaProps = (propName) => {
    const schemaProps = {
      fullName: Joi.string().required().label('Full Name'),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .message('Invalid email')
        .required()
        .label('Email'),
      phone: Joi.string().label('Phone').allow(''),
      message: Joi.string().required().label('Message'),
      isChecked: Joi.boolean()
        .valid(true)
        .messages({
          'any.only': 'You must agree to the terms and conditions',
        })
        .label('Agreement'),
    };
  
    return propName ? schemaProps[propName] : schemaProps;
  };

  schema = Joi.object(this.schemaProps());

  // Submiting to server
  doSubmit = () => {
    console.log('Submit');

    const data = { ...this.state.data };
    const { REACT_APP_TEMPLATE_CONTACT_FORM } = process.env; // ID of Template for Contact Form
    return { data, templateId: REACT_APP_TEMPLATE_CONTACT_FORM };
  };

  componentDidMount() {
    if (!this.props.isEnglishLang) {
      this.setState({...this.state, data: {...this.state.data, isChecked: false}})
    }
    // this.schema = Joi.object(this.schemaProps());
  }

  render() {
    const { data, errors } = this.state;
    const { t } = this.props;

    return (
      <form className="form form--contact" onSubmit={this.handleSubmit}>
        <div className="form__fields">
          {this.renderInput(
            'fullName',
            t('contactPageFormFullName'),
            t('contactPageFormFullNamePlaceholder')
          )}
          {this.renderInput('email', 'Email', t('contactPageFormEmailPlaceholder'))}

          <InputMask
            mask={'+999999999999999'}
            maskChar={null}
            value={data.phone}
            onChange={this.handleChange}
            error={errors.phone}
          >
            {(inputProps) =>
              this.renderInput(
                'phone',
                t('contactPageFormPhone'),
                '+380 _ _  _ _ _  _ _ _ _',
                {
                  ...inputProps,
                }
              )
            }
          </InputMask>

          {this.renderTextarea(
            'message',
            t('contactPageFormMessage'),
            t('contactPageFormMessagePlaceholder')
          )}

          <div className="text-tiny">
            {this.props.isEnglishLang && (
              <>
                *By sending this, you confirm acknowledgment of our{' '}
                <Link to="/privacy-policy" className="text-tiny__link">
                  Privacy Policy
                </Link>
                .
              </>
            )}
            {!this.props.isEnglishLang && (
              <>      
                <input
                  type="checkbox"
                  name="isChecked"
                  checked={data?.isChecked}
                  onChange={this.handleChange}
                />    
                <span>
                &nbsp;&nbsp;*Я даю згоду на обробку моїх персональних даних, наведених у контактній формі вище, з метою відповіді на мій запит і будь-яку подальшу кореспонденцію.
                </span>
              </>
            )}
          </div>
        </div>

        {this.renderButton(t('contactPageFormButton'))}

        {this.renderMessage()}
      </form>
    );
  }
}

export default ContactForm;
