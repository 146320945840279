import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Meta from '../../utils/appendMeta';
import ContactForm from './ContactForm';
import ContactCard from './ContactCard';

import Promo from '../../components/commons/Promo';
import HeaderBackground from '../../components/commons/HeaderBackground';
import T_WORD from '../../components/letters/T_WORD';
import ResultModal from '../../components/Modal/ResultModal';

import H1 from '../../components/typography/H1';
import Paragraph from '../../components/typography/Paragraph';

import { isEnglish } from '../../utils/helpers';

const metaData = {
  title: 'Contact Us',
  description: 'Stay Get in Touch with Us',
};

const Contact = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isEnglishLang = isEnglish(language);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [time, setTime] = useState(new Date());
  const tick = () => setTime(new Date());

  useEffect(() => {
    const clock = setInterval(() => tick(), 10000);
    return () => {
      clearInterval(clock);
    };
  }, [isModalOpen]);

  const localTime = () => {
    const locale = isEnglishLang ? 'en-US' : 'uk-UA';
    const use12hour = isEnglishLang;
    const localTime = time.toLocaleTimeString(locale, {
      timeZone: 'Europe/Kiev',
      hour: 'numeric',
      minute: '2-digit',
      hour12: use12hour,
    });
    return localTime;
  };
    // Create a ref for the contact section
    const contactSectionRef = useRef(null);

    // Function to scroll to the contact section
    const scrollToContactSection = () => {
      if (contactSectionRef.current) {
        contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    // Optionally, call the scroll function when the component mounts or based on some condition
    useEffect(() => {
      // Example: Scroll to the section when the component mounts
      scrollToContactSection();
    }, []);

  return (
    <>
      <Meta metaData={metaData} />

      <section className="contact-us">
        <HeaderBackground />

        <div className="contact-us__header">
          <div className="container">
          <section ref={contactSectionRef} id="contact-section-view"></section>
            <H1 className="contact-us__title">
              {isEnglish(language) && <T_WORD start="Con" end="act" />}
              {!isEnglish(language) && t('contactPageTitle')}
            </H1>

            <Paragraph className="contact-us__description">
              {t('contactPageDescription')}
            </Paragraph>
          </div>
        </div>
        <div className="contact-us__body">
          <div className="container">
            <div className="contact-us__body-container">
              <ContactCard localTime={localTime()} />
              <ContactForm
                setIsModalOpen={setIsModalOpen}
                isEnglishLang={isEnglishLang}
                t={t}
              />
            </div>
          </div>
        </div>

        {isEnglish(language) && (
          <div className="container">
            <Promo
              startText="WANT to KNOW WHY WE’RE "
              secondaryText="THE BEST"
              endText=" AT WHAT WE DO ?"
              isActive={1}
            />
          </div>
        )}
      </section>

      <ResultModal
        open={isModalOpen}
        title={t('contactPageFormResultTitle')}
        description={t('contactPageFormResultDescription')}
      />
    </>
  );
};

export default Contact;
