import { useEffect, useState } from 'react';
import FilterItem from './FilterItem';
import FilterCloseButton from './FilterCloseButton';
import FilterModal from './FilterModal';
import { useTranslation } from 'react-i18next';

export default function FiltersMobile({ handleFilterCases, selectedFilters }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(true);

  useEffect(() => {
    setIsAllSelected(!selectedFilters.length);
  }, [selectedFilters]);

  const handleModalView = () => setIsModalOpen((isOpen) => !isOpen);

  return (
    <div className="container">
      <div className="case-studies-page__filters tablet">
        <div className="filter-plank">
          <FilterItem
            item="All"
            isActiveForce={isAllSelected}
            handleFilterCases={handleFilterCases}
          />

          <div className="filter-plank-details" onClick={handleModalView}>
            <div className="filter-plank-title">{t('Filter')}</div>
            <FilterCloseButton />
          </div>
        </div>

        <FilterModal
          isModalOpen={isModalOpen}
          closeModal={handleModalView}
          currentSelectedFilters={selectedFilters}
          handleFilterCases={handleFilterCases}
        />
      </div>
    </div>
  );
}
