const aiProjects = [
  {
    id: 1,
    slug: 'ippt',
    title: 'Ippt+',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'ippt-ai.svg',
    preview: 'ippt-preview.gif',
    description:
      'A newly designed IPPT+ app aims to be your friendly companion to help you reach your goals (using power of CharGPT).',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 2,
    slug: 'homework',
    title: 'HOMEWORK AI',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'homework-ai.svg',
    preview: 'homework-preview.gif',
    description:
      'HomeWork AI is the ultimate study buddy. Harness the power AI to get help with math equations, multiple choice, written questions, and full blown essay writing.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 3,
    slug: 'parenting-coach',
    title: 'AI PARENTING COACH',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'coach-ai.svg',
    preview: 'coach-preview.gif',
    description:
      'AI Parenting Coach is a groundbreaking parenting advice app that harnesses the power of artificial intelligence to provide unparalleled support and guidance to parents.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 4,
    slug: 'hapday',
    title: 'HAPDAY: AI LIFE COACH',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'hapday-ai.svg',
    preview: 'hapday-preview.gif',
    description:
      'Discover a happier, healthier life with the help of your personal Wellbeing Assistant!',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 5,
    slug: 'plum',
    title: 'PLUM',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'plum-ai.svg',
    preview: 'plum-preview.gif',
    description:
      'An innovative iOS application which helps people to maintain healthy activities and food consumption.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 6,
    slug: 'lelascan',
    title: 'LELASCAN',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'lelascan-ai.svg',
    preview: 'lelascan-preview.gif',
    description:
      'A document scanning application for a DE market, which is used by freelancers all over the country.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 7,
    slug: 'help-ai',
    title: 'HELP AI: CHAT & ASK EXPERTS',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'helpchat-ai.svg',
    preview: 'help-preview.gif',
    description:
      'Meet Help AI, your ultimate mobile companion offering instant access to over 450 AI-powered experts across a multitude of fields.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 8,
    slug: 'brainybot',
    title: 'BRAINYBOT',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'brainybot-ai.svg',
    preview: 'brainybot-preview.gif',
    description:
      'A powerful tool that can provide users with quick, accurate and personalized responses to their questions, making it an asset to individuals and organizations alike.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
];

export default aiProjects;
