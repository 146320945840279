const caseStudies = [
    {
      id: 1,
      slug: 'plum',
      title: 'Plum',
      tags: [
        'Мобільна Розробка',
        'Навчання',
        'Дизайн',
        'Веб Розробка',
        'Медицина',
      ],
      imgSrc: 'plum-case.png',
      preview: 'plum-preview.gif',
      description: 'Додаток з інтеграцією штучного інтелекту для наукових досліджень',
      platform: ['iOS'],
      industry: ['Медицина'],
      duration: '1 рік',
      discovery:
        'До нашої команди звернувся Університет Отаго з Нової Зеландії з проханням розробити iOS-додаток, який би дозволяв відстежувати споживання їжі та покращувати раціон користувачів, для боротьби з ознаками діабету та ожирінням. Для цього ми запропонували інтегрувати ШІ бібліотеку розпізнавання зображень у додаток (https://www.passio.ai/), щоб користувачі могли “сканувати” їжу за допомогою камери смартфону та бачити дані про харчову цінність продуктів',
      solution:
        'iOS-додаток з Firebase бекендом та інтеграцією Passio.ai (для розпізнавання їжі). Додаток успішно запущений та зараз проходить етап тестування на стороні клієнта',
      goals:
        'Зрозуміти харчові звички користувачів (їжа та напої) та використовувати ці дані для наукових досліджень (для боротьби з проблемами ожиріння в регіоні).',
    },
    {
      id: 2,
      slug: 'buzzer',
      title: 'Buzzer',
      tags: [
        'Мобільна Розробка',
        'Соціальні Мережі',
        'Дизайн',
        'Веб Розробка',
        'Розваги',
      ],
      imgSrc: 'buzzer-case.png',
      preview: 'buzzer-preview.gif',
      description:
        'Перша у світі соціальна медіаплатформа, де можна заробити ставши популярним.',
      platform: ['iOS'],
      industry: ['Соціальні Мережі / Розваги'],
      duration: '1 рік',
      discovery:
        'Наша команда отримала запит від клієнта з США, якому потрібна була допомога у запуску існуючого додатку.',
      solution:
        'Нами було запропоновано підхід до розробки, який дозволяв переробити більшу частину існуючого коду та інфраструктури, зберігаючи основний функціонал.',
      goals:
        'Переробити існуючий код (частково), виправити усі проблеми та додати новий функціонал. Рішення повинно було підтримувати 10 тисяч активних користувачів.',
    },
    {
      id: 3,
      slug: 'trail-tutor',
      title: 'Trail Tutor',
      tags: [
        'Дизайн',
        'Мобільна Розробка',
        'Веб Розробка',
        'Піший туризм',
        'Подорожі',
      ],
      imgSrc: 'trail-tutor-case.png',
      preview: 'trail-tutor-preview.gif',
      description: 'Мобільний додаток для для піших прогулянок та походів у гори.',
      platform: ['iOS'],
      industry: ['Подорожі'],
      duration: '1 рік',
      discovery:
        'Наша команда отримала запит на розробку iOS-додатку, який би дозволяв користувачам завантажувати маршрути піших походів та мапи для використання в автономному (оффлайн) режимі. Кожен маршрут повинен був мати набір контрольних точок з відео.',
      solution:
        'iOS-додаток з інтеграцією MapBox (для оффлайн мап) та Firebase для бекенду. Додаток завантажує дані мапи та відео під час першого встановлення, а згодом перевіряє наявність оновлень при переходах з екрану на екран. Бібліотека CoreData використовується для зберігання даних у оффлайн режимі на самому пристрої.',
      goals:
        'Створити додаток, який міг би працювати в повністю автономному режимі (у регіонах де відсутній або слабкий зв\'язок), забезпечуючи доступ до більшості функцій додатку.',
    },
  ];
  
  export default caseStudies;
  