import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/buttons/Button';
import H4 from '../../components/typography/H4';
import { isEnglish } from '../../utils/helpers';

export default function InTouch() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <>
      <div className="heading">
        <H4 $align="left" $color="#011730">
          {t('footerInTouchTop')}
        </H4>
        <H4 $align="left" $color="#011730">
          {t('footerInTouchMiddle')}
        </H4>
        <H4 $align="left" $color="#011730">
          {t('footerInTouchBottom')}
        </H4>
      </div>

      <Link
        to={'/contact'}
        className="nav__link "
      >
        <Button
          $primaryDark
          $width="312px"
          $height="48px"
          className="intouch-mobile"
        >
          {t('inTouchBtn')}
        </Button>
      </Link>
    </>
  );
}
