import FilterItem from './FilterItem';
import { getFilterNamesUa } from '../helpers';

// const filterItems = getFilterNames();
const filterItems = getFilterNamesUa();

export default function FiltersDesktop({ handleFilterCases }) {
  return (
    <div className="container">
      <div className="case-studies-page__filters desktop">
        {filterItems.map((item, i) => {
          return (
            <FilterItem item={item} key={i} handleFilterCases={handleFilterCases} />
          );
        })}
      </div>
    </div>
  );
}
