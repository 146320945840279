export function getFilterNames() {
  return [
    'Mobile development',
    'Web Development',
    'UI/UX Design',
    'Social',
    'Health Care',
    'Education',
    'Entertainment',
    'Travel',
    'Hiking',
  ];
}
export function getFilterNamesUa() {
  return [
    'Мобільна Розробка',
    'Веб Розробка',
    'Дизайн',
    'Соціальні Мережі',
    'Медицина',
    'Навчання',
    'Розваги',
    'Подорожі',
  ];
}
