import classNames from 'classnames';
import HeaderBackground from '../../../components/commons/HeaderBackground';
import Promo from '../../../components/commons/Promo';
import TechnologyItem from '../../../components/TechnologyItem/TechnologyItem';
import ItemCard from '../../../components/cards/ItemCard';
import Circle from '../../../components/figures/Circle';

import ButtonText from '../../../components/typography/ButtonText';
import H1 from '../../../components/typography/H1';
import H2 from '../../../components/typography/H2';
import Paragraph from '../../../components/typography/Paragraph';
import { isEnglish } from '../../../../src/utils/helpers';
import { useTranslation } from 'react-i18next';

export default function CaseItem({ project }) {
  const preparedTitle = prepareTitle(project.title);
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <section className="section-case-item">
      <HeaderBackground />

      <div className="section-case-item__title">
        <div className="container">{preparedTitle}</div>
      </div>

      <div className="container">
        <div className="section-case-item__description">
          <Paragraph>{project.description}</Paragraph>
        </div>
      </div>

      <Preview project={project} />

      <Technology project={project} />

      <Discovery discovery={project.discovery} />

      <Goals goals={project.goals} />

      <Solution solution={project.solution} />
      {isEnglish(language) && 
        <PromoComponent />
      }
    </section>
  );
}

function Preview({ project }) {
  return (
    <div className="container section-case-item__preview">
      <ItemCard $height="100%" className="section-case-item__preview-card">
        <img
          src={`/img/case-studies/${project.preview}`}
          alt={project.title}
          className={classNames('section-case-item__preview-image', {
            notebook: project.title === 'Seon',
          })}
        />
      </ItemCard>
    </div>
  );
}

function Technology({ project }) {
  const { t } = useTranslation();
  return (
    <div className="container section-case-item__technology">
      <div className="platfom">
        <ButtonText $align="left" $color="#F3F8FA">
          {t('platform')}
        </ButtonText>

        {project.platform.map((p) => {
          return (
            <TechnologyItem name={p?.toUpperCase()} key={p}>
              {p}
            </TechnologyItem>
          );
        })}
      </div>

      <div className="industry">
        <ButtonText $align="left" $color="#F3F8FA">
          {t('industry')}
        </ButtonText>

        {project.industry.map((p) => {
          return (
            <TechnologyItem name={p?.toUpperCase()} key={p}>
              {p}
            </TechnologyItem>
          );
        })}
      </div>

      <div className="duration">
        <ButtonText $align="left" $color="#F3F8FA">
        {t('duration')}
        </ButtonText>

        <TechnologyItem name="DURATION">{project.duration}</TechnologyItem>
      </div>
    </div>
  );
}

function Discovery({ discovery }) {
  const { t } = useTranslation();
  return (
    <div className="container section-case-item__content">
      <div className="section-case-item__content-description">
        <H2 $color="#50C5EA" $align="left">
          {t('discovery')}
        </H2>
        <Paragraph $align="left">{discovery}</Paragraph>
      </div>
      <div className="section-case-item__content-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function Solution({ solution }) {
  const { t } = useTranslation();
  return (
    <div className="container section-case-item__content">
      <div className="section-case-item__content-description">
        <H2 $color="#50C5EA" $align="left">
          {t('solution')}
        </H2>
        <Paragraph $align="left">{solution}</Paragraph>
      </div>
      <div className="section-case-item__content-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function Goals({ goals }) {
  const { t } = useTranslation();
  return (
    <div className="container section-case-item__content reverse">
      <div className="section-case-item__content-description">
        <H2 $color="#50C5EA" $align="left">
          {t('goals')}
        </H2>
        <Paragraph $align="left">{goals}</Paragraph>
      </div>
      <div className="section-case-item__content-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function PromoComponent() {
  return (
    <div className="container section-case-item__promo">
      <Promo
        startText={
          <>
            Ready to talk? <br />
          </>
        }
        secondaryText="Let’s build"
        endText="something great together"
        isActive={1}
      />
    </div>
  );
}

function prepareTitle(title) {
  const loweredTitle = title.toLowerCase();
  let preparedTitle = '';

  if (loweredTitle === 'vixinity') {
    preparedTitle = highliteLetter('vixini', 't', 'y');
  }
  if (loweredTitle === 'plum') {
    preparedTitle = highliteLetter('p', 'l', 'um');
  }
  if (loweredTitle === 'buzzer') {
    preparedTitle = highliteLetter('buzz', 'e', 'r');
  }
  if (loweredTitle === 'hyperlocal') {
    preparedTitle = highliteLetter('hyp', 'e', 'rlocal');
  }
  if (loweredTitle === 'seon') {
    preparedTitle = highliteLetter('s', 'e', 'on');
  }
  if (loweredTitle === 'trail tutor') {
    preparedTitle = highliteLetter('trail ', 't', 'utor');
  }

  return <H1>{preparedTitle}</H1>;
}

function highliteLetter(startPart, letter, endPart) {
  let letterPart = 't-part';
  if (letter === 'l') {
    letterPart = 'l-part';
  }
  if (letter === 'e') {
    letterPart = 'e-part';
  }

  return (
    <>
      {startPart.toUpperCase()}
      <span className={`cate-title-letter ${letterPart}`}>
        {letter.toUpperCase()}
      </span>
      {endPart.toUpperCase()}
    </>
  );
}
